import type { ExtractPropTypes } from "vue";
import type { gDrawerEmits, gDrawerProps } from "./src/drawer";
import Drawer from "./src/drawer";
import { withInstall } from "@gejia-element-plus/utils";

export const GDrawer = withInstall(Drawer);
export default GDrawer;

export { gDrawerProps, gDrawerEmits };

export type GDrawerInstance = InstanceType<typeof Drawer>;

export type GDrawerProps = ExtractPropTypes<typeof gDrawerProps>;

export type GDrawerEmits = typeof gDrawerEmits;
