import type { ExtractPropTypes } from "vue";
import TreeSelect, { gTreeSelectEmits, gTreeSelectProps } from "./src/treeSelect";
import { withInstall } from "@gejia-element-plus/utils";

export const GTreeSelect = withInstall(TreeSelect);
export default GTreeSelect;

export { gTreeSelectProps, gTreeSelectEmits };

export type GTreeSelectInstance = InstanceType<typeof TreeSelect>;

export type GTreeSelectProps = ExtractPropTypes<typeof gTreeSelectProps>;

export type GTreeSelectEmits = typeof gTreeSelectEmits;
