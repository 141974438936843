import type { ExtractPropTypes } from "vue";
import SelectPage, { gSelectPageEmits, gSelectPageProps } from "./src/selectPage";
import { withInstall } from "@gejia-element-plus/utils";

export const GSelectPage = withInstall(SelectPage);
export default GSelectPage;

export { gSelectPageProps, gSelectPageEmits };

export type GSelectPageInstance = InstanceType<typeof SelectPage>;

export type GSelectPageProps = ExtractPropTypes<typeof gSelectPageProps>;

export type GSelectPageEmits = typeof gSelectPageEmits;
