import type { ExtractPropTypes } from "vue";
import type { gAvatarEmits, gAvatarProps } from "./src/avatar";
import Avatar from "./src/avatar";
import { withInstall } from "@gejia-element-plus/utils";

export const GAvatar = withInstall(Avatar);
export default GAvatar;

export { gAvatarProps, gAvatarEmits };

export type GAvatarInstance = InstanceType<typeof Avatar>;

export type GAvatarProps = ExtractPropTypes<typeof gAvatarProps>;

export type GAvatarEmits = typeof gAvatarEmits;
