import type { ExtractPropTypes } from "vue";
import type { gTagEmits, gTagProps } from "./src/tag";
import Tag from "./src/tag";
import { withInstall } from "@gejia-element-plus/utils";

export const GTag = withInstall(Tag);
export default GTag;

export { gTagProps, gTagEmits };

export type GTagInstance = InstanceType<typeof Tag>;

export type GTagProps = ExtractPropTypes<typeof gTagProps>;

export type GTagEmits = typeof gTagEmits;
