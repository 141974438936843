import { defineComponent } from "vue";
import { QuestionFilled } from "@element-plus/icons-vue";
import { ElIcon, ElTooltip } from "element-plus";
import { makeSlots, useRender } from "@gejia-element-plus/utils";

export const gFormItemTipProps = {
	/** @description 提示 */
	tips: String,
	/** @description 显示 */
	label: String,
};

type GFormItemTipSlots = {
	/** @description 显示插槽 */
	label: never;
};

export default defineComponent({
	name: "GFormItemTip",
	props: gFormItemTipProps,
	slots: makeSlots<GFormItemTipSlots>(),
	setup(props, { slots }) {
		useRender(() => (
			<div class="g-form-item-tip">
				<ElTooltip effect="dark" rawContent content={props.tips} placement="top">
					<ElIcon>
						<QuestionFilled />
					</ElIcon>
				</ElTooltip>
				<span class="g-form-item-tip__label">{slots.label ? slots.label() : props.label}</span>
			</div>
		));
	},
});
