import type { ExtractPropTypes } from "vue";
import Upload, { gUploadEmits, gUploadProps } from "./src/upload";
import { withInstall } from "@gejia-element-plus/utils";

export const GUpload = withInstall(Upload);
export default GUpload;

export { gUploadProps, gUploadEmits };

export type GUploadInstance = InstanceType<typeof Upload>;

export type GUploadProps = ExtractPropTypes<typeof gUploadProps>;

export type GUploadEmits = typeof gUploadEmits;
