import type { ExtractPropTypes } from "vue";
import Button, { gButtonEmits, gButtonProps } from "./src/button";
import { withInstall } from "@gejia-element-plus/utils";

export const GButton = withInstall(Button);
export default Button;

export { gButtonProps, gButtonEmits };

export type GButtonInstance = InstanceType<typeof Button>;

export type GButtonProps = ExtractPropTypes<typeof gButtonProps>;

export type GButtonEmits = typeof gButtonEmits;
