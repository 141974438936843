import type { App } from "vue";
import GejiaElementPlusComponents from "./component";
import GejiaElementPlusDirectives from "./directive";
import { installElementPlus } from "./element-plus";
import { version } from "./version";
import GejiaElementPlusIconsVue from "@gejia-element-plus/icons-vue";
import { GejiaApp, type GejiaAppOptions } from "@gejia-element-plus/settings";
import { errorHandler, makeIdentity } from "@gejia-element-plus/utils";

export const INSTALLED_KEY = Symbol("INSTALLED_KEY");

export const makeInstaller = (): {
	version: string;
	install: (app: App, options?: GejiaAppOptions) => void;
} => {
	const install = (app: App, options?: GejiaAppOptions): void => {
		if (app[INSTALLED_KEY]) return;

		app[INSTALLED_KEY] = true;

		// 全局异常捕获
		app.config.errorHandler = errorHandler;

		makeIdentity();

		if (options) {
			GejiaApp.setAppOptions(options);
		}

		installElementPlus(app);

		/** 注册 Gejia Icon 组件库 */
		app.use(GejiaElementPlusIconsVue);

		GejiaElementPlusComponents.forEach((c) => app.use(c));

		GejiaElementPlusDirectives.forEach((d) => app.use(d));
	};

	return {
		version,
		install,
	};
};
