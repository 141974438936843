import type { Ref } from "vue";
import type { ElForm, FormItemRule, FormValidationResult } from "element-plus";
import { RegExps } from "@gejia-element-plus/constants";
import { consoleWarn } from "@gejia-element-plus/utils";

export const formUtil = {
	/**
	 * ElForm 表单验证
	 * @param elFormRef ElForm 组件ref
	 */
	validate(elFormRef: Ref<InstanceType<typeof ElForm>>): FormValidationResult {
		return new Promise<boolean>((resolve, reject) => {
			elFormRef.value.validate((isValid: boolean, invalidFields?) => {
				if (isValid) {
					resolve(isValid);
				} else {
					if (invalidFields) {
						consoleWarn("formUtil", false, invalidFields);
					}
					reject(isValid);
				}
			});
		});
	},
	/**
	 * ElForm 表单验证，带滚动
	 * @param elFormRef ElForm 组件ref
	 */
	validateScrollToField(elFormRef: Ref<InstanceType<typeof ElForm>>): FormValidationResult {
		return new Promise<boolean>((resolve, reject) => {
			elFormRef.value.validate((isValid: boolean, invalidFields?) => {
				if (isValid) {
					resolve(isValid);
				} else {
					elFormRef.value.scrollToField(Object.keys(invalidFields));
					if (invalidFields) {
						consoleWarn("formUtil", false, invalidFields);
					}
					reject(isValid);
				}
			});
		});
	},
	/**
	 * 手机号
	 */
	mobile(rule: any, value: string, callback: any): void {
		if (!value) {
			callback(new Error("请输入手机号码"));
		} else if (RegExps.Mobile.test(value)) {
			callback();
		} else {
			callback(new Error("请输入正确的手机号码"));
		}
	},
	/**
	 * 电话号码
	 */
	phone(rule: any, value: string, callback: any): void {
		if (!value) {
			callback();
		} else if (RegExps.Phone.test(value)) {
			callback();
		} else {
			callback(new Error("请输入正确的电话号码"));
		}
	},
	/**
	 * 邮箱
	 */
	email(rule: any, value: string, callback: any): void {
		if (!value) {
			callback();
		} else if (RegExps.Email.test(value)) {
			callback();
		} else {
			callback(new Error("请输入正确的邮箱地址"));
		}
	},
	/**
	 * 身份证
	 */
	idCard(rule: any, value: string, callback: any): void {
		if (RegExps.IdCard.test(value) || !value) {
			callback();
		} else {
			callback(new Error("请输入正确的身份证号码"));
		}
	},
	/**
	 * 车牌号
	 */
	carNumber(rule: any, value: string, callback: any): void {
		let success = false;
		if (value?.length === 7) {
			success = RegExps.CarNumber.test(value);
		} else if (value?.length === 8) {
			success = RegExps.NewEnergyCarNumber.test(value);
		}
		if (success) {
			callback();
		} else {
			callback(new Error("请输入正确的车牌号码"));
		}
	},
	/**
	 * editor 必填
	 */
	editorRequired(rule: any, value: string, callback: any): void {
		if (value === "<p><br></p>") {
			return callback(new Error("内容不能为空"));
		}
		return callback();
	},
	/**
	 * 外部链接
	 */
	external(rule: any, value: string, callback: any): void {
		if (RegExps.External.test(value)) {
			callback();
		} else {
			callback(new Error("请输入正确的外部链接"));
		}
	},
	/**
	 * 有效的URL
	 */
	url(rule: any, value: string, callback: any): void {
		if (RegExps.Url.test(value)) {
			callback();
		} else {
			callback(new Error("请输入有效的Url"));
		}
	},
	/**
	 * 邮政编码
	 */
	postCode(rule: any, value: string, callback: any): void {
		if (RegExps.PostCode.test(value)) {
			callback();
		} else {
			callback(new Error("请输入有效的邮政编码"));
		}
	},
	/**
	 * 账号（6-20位字母或数字，必须以字母开头，不能全为数字）
	 */
	account(rule: any, value: string, callback: any): void {
		if (RegExps.Account.test(value)) {
			callback();
		} else {
			callback(new Error("请输入有效的账号（6-20位字母或数字，必须以字母开头，不能全为数字）"));
		}
	},
	/**
	 * 弱密码（只包含字母和数字，长度为6到18位）
	 */
	password(rule: any, value: string, callback: any): void {
		if (RegExps.Password.test(value)) {
			callback();
		} else {
			callback(new Error("请输入有效的密码（只包含字母和数字，长度为6到18位）"));
		}
	},
	/**
	 * 强密码（8到20位，包含大小写字母、数字及特殊字符）
	 */
	strongPassword(rule: any, value: string, callback: any): void {
		if (RegExps.StrongPassword.test(value)) {
			callback();
		} else {
			callback(new Error("请输入有效的密码（8到20位，包含大小写字母、数字及特殊字符）"));
		}
	},
	/**
	 * 中文字符
	 */
	chinese(rule: any, value: string, callback: any): void {
		if (RegExps.Chinese.test(value)) {
			callback();
		} else {
			callback(new Error("请输入有效的中文字符"));
		}
	},
	/**
	 * 英文字符
	 */
	english(rule: any, value: string, callback: any): void {
		if (RegExps.English.test(value)) {
			callback();
		} else {
			callback(new Error("请输入有效的英文字符"));
		}
	},
	/**
	 * 文本框必填
	 */
	inputRequired(message: string, trigger = "blur"): FormItemRule {
		return {
			required: true,
			message,
			trigger,
		};
	},
	/**
	 * 选择器必选
	 */
	selectRequired(message: string, trigger = "change"): FormItemRule {
		return {
			required: true,
			message,
			trigger,
		};
	},
};
