import type { CSSProperties } from "vue";
import { computed, defineComponent, h, reactive, resolveComponent } from "vue";
import { ElIcon } from "element-plus";
import { RegExps } from "@gejia-element-plus/constants";
import { addUnit, useRender } from "@gejia-element-plus/utils";

export const gIconProps = {
	/** @description el-icon- 使用 El-icon 的图标；g-icon 使用 Gejia 图标组件库； */
	name: {
		type: String,
		required: true,
	},
	/** @description 大小 */
	size: {
		type: [String, Number],
	},
	/** @description 颜色*/
	color: String,
};

export default defineComponent({
	name: "GIcon",
	props: gIconProps,
	setup(props, { attrs }) {
		const state = reactive({
			isUrl: computed(() => RegExps.External.test(props.name)),
			style: computed((): CSSProperties => {
				const result: any = {};
				if (props.size) {
					result.size = addUnit(props.size);
				}
				if (props.color) {
					result.color = props.color;
				}
				if (state.isUrl) {
					result.mask = `url(${props.name}) no-repeat 50% 50%`;
					result["-webkit-mask"] = `url(${props.name}) no-repeat 50% 50%`;
				}
				return result;
			}),
		});

		useRender(() =>
			props.name.indexOf("el-icon-") === 0 ? (
				<ElIcon {...props} class={["g-icon", props.name]}>
					{h(resolveComponent(props.name))}
				</ElIcon>
			) : props.name.indexOf("g-icon") === 0 ? (
				<ElIcon {...props} class={["g-icon", props.name]}>
					{h(resolveComponent(props.name))}
				</ElIcon>
			) : state.isUrl ? (
				<div class="el-icon g-icon url-icon" style={state.style} />
			) : (
				<i class={["el-icon g-icon", props.name]} style={state.style} />
			)
		);
	},
});
