import type { ExtractPropTypes } from "vue";
import type { gTableEmits, gTableProps } from "./src/table";
import Table, { tableProps } from "./src/table";
import TableColumn, { tableColumnProps } from "./src/tableColumn";
import TableColumnsSettingDialog from "./src/tableColumnSettingDialog";
import TableExportDialog from "./src/tableExportDialog";
import TablePagination from "./src/tablePagination";
import TableSearchForm from "./src/tableSearchForm";
import TableSearchFormItem from "./src/tableSearchFormItem";
import { withInstall, withNoopInstall } from "@gejia-element-plus/utils";

export const GTable = withInstall(Table, {
	TableColumn,
	TableColumnsSettingDialog,
	TableExportDialog,
	TablePagination,
	TableSearchForm,
	TableSearchFormItem,
});
export default GTable;

export const GTableColumn = withNoopInstall(TableColumn);

export * from "./src/page.type";
export * from "./src/table.state";
export * from "./src/table.type";

export { tableProps, tableColumnProps, gTableProps, gTableEmits };

export type GTableInstance = InstanceType<typeof Table>;

export type GTableProps = ExtractPropTypes<typeof gTableProps>;

export type GTableEmits = typeof gTableEmits;

export type GTableColumnInstance = InstanceType<typeof TableColumn>;
