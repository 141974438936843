import type { ExtractPropTypes } from "vue";
import type { gIconProps } from "./src/icon";
import Icon from "./src/icon";
import { withInstall } from "@gejia-element-plus/utils";

export const GIcon = withInstall(Icon);
export default GIcon;

export { gIconProps };

export type GIconInstance = InstanceType<typeof Icon>;

export type GIconProps = ExtractPropTypes<typeof gIconProps>;
