import { Fragment, computed, defineComponent, reactive } from "vue";
import { ElPopover, ElScrollbar, useGlobalSize } from "element-plus";
import { isFunction } from "lodash-unified";
import type { RouteRecord, RouteRecordRaw } from "vue-router";
import { useRoute, useRouter } from "vue-router";
import { GIcon } from "@gejia-element-plus/components/icon";
import { addUnit, definePropType, routerUtil, useRender, withDefineType } from "@gejia-element-plus/utils";

type ModuleMenuComponentProps = {
	/** @description 指定隐藏为路由meta节点的某个属性 */
	hide?: string | ((data: RouteRecordRaw) => boolean);
	/** @description 指定标题为路由meta节点的某个属性 */
	title?: string | ((data: RouteRecordRaw) => string);
	/** @description 指定图标为路由meta节点的某个属性 */
	icon?: string | ((data: RouteRecordRaw) => string);
};

const getRouterPropsValue = <T,>(key: string | ((...args) => T), data: RouteRecordRaw): T => {
	if (isFunction(key)) {
		return key(data);
	} else {
		return data.meta[key] as T;
	}
};

export default defineComponent({
	name: "GModuleMenu",
	props: {
		/** @description 高度 */
		height: {
			type: [String, Number],
			default: "55px",
		},
		/** @description 根节点路由名称 */
		rootRouteName: {
			type: String,
			default: "layout",
		},
		/** @description 配置选项 */
		props: {
			type: definePropType<ModuleMenuComponentProps>(Object),
			default: (): Partial<ModuleMenuComponentProps> => ({
				hide: "hide",
				title: "title",
				icon: "icon",
			}),
		},
	},
	setup(props) {
		const _globalSize = useGlobalSize();

		const router = useRouter();
		const route = useRoute();

		const state = reactive({
			// 直接默认查找 layout 下的路由，其余的直接忽略
			menuList: computed(() => {
				const rootRouters = (router
					.getRoutes()
					.find((f) => f.name === props.rootRouteName)
					?.children?.filter((f) => !getRouterPropsValue(props.props.hide, f)) ?? []) as RouteRecord[];

				const getChildrenPath = (routers: RouteRecord[]): string[] => {
					const paths: string[] = [];
					routers?.forEach((item) => {
						paths.push(item.path);
						if (item?.children?.length > 0) {
							paths.push(...getChildrenPath(item.children as RouteRecord[]));
						}
					});
					return paths;
				};

				return rootRouters.map((m) => {
					m.meta = {
						...(m.meta ?? {}),
						__childrenPaths: [m.path, ...getChildrenPath(m.children as RouteRecord[])],
					};
					return m;
				});
			}),
			popoverVisible: [],
			curPopoverIndex: -1,
			curEl: withDefineType<HTMLElement>(),
		});

		const isNodeContains = (element: HTMLElement, target: HTMLElement): boolean => {
			const childNodes = element.childNodes;
			for (let i = 0; i < childNodes.length; i++) {
				const node = childNodes[i];
				if (node.childNodes?.length > 0) {
					const tryA = isNodeContains(node as HTMLElement, target);
					if (tryA) {
						return true;
					}
				}
				if (node.nodeType === Node.ELEMENT_NODE && node === target) {
					return true;
				}
			}
			return false;
		};

		const handleMenuItemClick = (menu: RouteRecordRaw, index: number): void => {
			if (menu.path === route.path) return;
			state.popoverVisible[index] = false;
			routerUtil.routePushSafe(router, menu.path);
		};

		const handleHidePopover = (event: MouseEvent): void => {
			const { curPopoverIndex, curEl } = { ...state };
			const groupEl = document.querySelector(`.__g-module-menu-popover__group-${curPopoverIndex}`) as HTMLElement;
			if (groupEl) {
				const newTarget = event.target as HTMLElement;
				if (curEl != event.target && !isNodeContains(groupEl, newTarget)) {
					state.popoverVisible[curPopoverIndex] = false;
					document.removeEventListener("click", handleHidePopover);
				}
			}
		};

		const handleMenuClick = (event: MouseEvent, menu: RouteRecordRaw, index: number): void => {
			if (menu.children?.length > 0) {
				state.popoverVisible = state.popoverVisible.map(() => false);
				state.popoverVisible[index] = true;
				state.curPopoverIndex = index;
				state.curEl = event.target as HTMLElement;
				document.addEventListener("click", handleHidePopover);
			} else {
				if (menu.path === route.path) return;
				routerUtil.routePushSafe(router, { path: menu.path });
			}
		};

		useRender(() => (
			<ElScrollbar class={["g-module-menu", `g-module-menu-${_globalSize.value}`]} style={{ "--height": addUnit(props.height) }}>
				{state.menuList.map((item, index) => (
					<Fragment>
						{item.children?.length > 0 ? (
							<ElPopover
								popperClass={`g-module-menu-popover g-module-menu-popover-${_globalSize.value}`}
								visible={state.popoverVisible[index]}
								placement="right-start"
								width="auto"
								trigger="click"
								persistent={false}
								showArrow={false}
								offset={7}
								showAfter={0}
								hideAfter={0}
							>
								{{
									reference: () => (
										<div
											class={[
												"g-module-menu__list",
												{ "is-active": (item.meta.__childrenPaths as string[]).includes(route.path) },
											]}
											onClick={(event: MouseEvent) => handleMenuClick(event, item, index)}
										>
											<GIcon name={getRouterPropsValue(props.props.icon, item) ?? "g-icon-Menu"} />
											<span>{getRouterPropsValue(props.props.title, item)}</span>
										</div>
									),
									default: () => (
										<div class={["g-module-menu-popover__group", `__g-module-menu-popover__group-${index}`]}>
											{item.children
												?.filter((f) => !getRouterPropsValue(props.props.hide, f))
												.map((gItem) => (
													<div>
														<div class="g-module-menu-popover__group-title">
															{getRouterPropsValue(props.props.title, gItem)}
														</div>
														<ul class="g-module-menu-popover__group-list">
															{gItem.children
																?.filter((f) => !getRouterPropsValue(props.props.hide, f))
																.map((mItem) => (
																	<li
																		class="g-module-menu-popover__group-list-item"
																		onClick={() => handleMenuItemClick(mItem, index)}
																	>
																		<span>{getRouterPropsValue(props.props.title, mItem)}</span>
																	</li>
																))}
														</ul>
													</div>
												))}
										</div>
									),
								}}
							</ElPopover>
						) : (
							<div
								class={["g-module-menu__list", { "is-active": (item.meta.__childrenPaths as string[]).includes(route.path) }]}
								onClick={(event: MouseEvent) => handleMenuClick(event, item, index)}
							>
								<GIcon name={getRouterPropsValue(props.props.icon, item) ?? "g-icon-Menu"} />
								<span>{getRouterPropsValue(props.props.title, item)}</span>
							</div>
						)}
					</Fragment>
				))}
			</ElScrollbar>
		));
	},
});
