import type { ExtractPropTypes } from "vue";
import FormItemTip, { gFormItemTipProps } from "./src/formItemTip";
import { withInstall } from "@gejia-element-plus/utils";

export const GFormItemTip = withInstall(FormItemTip);
export default GFormItemTip;

export { gFormItemTipProps };

export type GFormItemTipInstance = InstanceType<typeof FormItemTip>;

export type GFormItemTipProps = ExtractPropTypes<typeof gFormItemTipProps>;
