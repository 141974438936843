import type { ExtractPropTypes } from "vue";
import type { gSelectEmits } from "./src/select";
import Select, { SelectProps, gSelectProps } from "./src/select";
import SelectOption from "./src/selectOption";
import { withInstall, withNoopInstall } from "@gejia-element-plus/utils";

export const GSelect = withInstall(Select, {
	SelectOption,
});
export default GSelect;

export const GSelectOption = withNoopInstall(SelectOption);

export { SelectProps, gSelectProps };
export * from "./src/select.type";

export type GSelectInstance = InstanceType<typeof Select>;

export type GSelectProps = ExtractPropTypes<typeof gSelectProps>;

export type GSelectEmits = typeof gSelectEmits;

export type GSelectOptionInstance = InstanceType<typeof SelectOption>;
