import type { ExtractPropTypes } from "vue";
import UploadImage, { gUploadImageEmits, gUploadImageProps } from "./src/uploadImage";
import { withInstall } from "@gejia-element-plus/utils";

export const GUploadImage = withInstall(UploadImage);
export default GUploadImage;

export { gUploadImageProps, gUploadImageEmits };

export type GUploadImageInstance = InstanceType<typeof UploadImage>;

export type GUploadImageProps = ExtractPropTypes<typeof gUploadImageProps>;

export type GUploadImageEmits = typeof gUploadImageEmits;
