import type { ExtractPropTypes } from "vue";
import Dialog, { gDialogEmits, gDialogProps } from "./src/dialog";
import { withInstall } from "@gejia-element-plus/utils";

export const GDialog = withInstall(Dialog);
export default GDialog;

export { gDialogProps, gDialogEmits };

export type GDialogInstance = InstanceType<typeof Dialog>;

export type GDialogProps = ExtractPropTypes<typeof gDialogProps>;

export type GDialogEmits = typeof gDialogEmits;
