import type { ExtractPropTypes, InjectionKey, SetupContext } from "vue";
import { computed, nextTick, provide, reactive, ref, watch, watchEffect } from "vue";
import type { TableInstance } from "element-plus";
import { ElMessage, ElMessageBox, ElNotification, dayjs, useGlobalSize } from "element-plus";
import { isArray, isFunction } from "lodash-unified";
import type { PageInput, PageResult } from "./page.type";
import type { GTableSlots, gTableEmits, gTableProps } from "./table";
import type { GTableState } from "./table.state";
import type { GTableColumnCtx, GTableEnumColumnCtx } from "./table.type";
import { getTableDefaultSlots } from "./table.type";
import { GejiaApp } from "@gejia-element-plus/settings";
import { clickUtil, consoleError, type makeSlots, tableUtil } from "@gejia-element-plus/utils";

export const tableStateKey: InjectionKey<GTableState> = Symbol("tableState");
export const enumMapKey: InjectionKey<Map<string, GTableEnumColumnCtx[]>> = Symbol("enumMap");

type TableSetupContext = SetupContext<typeof gTableEmits, ReturnType<typeof makeSlots<GTableSlots>>>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useTable = (props: ExtractPropTypes<typeof gTableProps>, slots: TableSetupContext["slots"], emit: TableSetupContext["emit"]) => {
	const _globalSize = useGlobalSize();

	const state: GTableState = reactive({
		loading: false,
		loadingText: "加载中...",
		orgColumns: [],
		tableColumns: computed(() => state.orgColumns.filter((f) => f.prop && !f.pureSearch)),
		searchColumns: computed(() =>
			state.orgColumns
				.filter((f) => f.pureSearch || f.search)
				.sort((a, b) => {
					return a.search?.order - b.search?.order;
				})
		),
		spanColumns: computed(() => [
			...state.orgColumns
				.filter((f) => f.spanProp)
				.map((col) => {
					return {
						prop: col?.prop,
						spanProp: col?.spanProp,
					};
				}),
			...(props.props.span
				? [
						{ prop: "__table-index", spanProp: props.props.span },
						{ prop: "__table-selection", spanProp: props.props.span },
						{ prop: "__table-operation", spanProp: props.props.span },
					]
				: []),
		]),
		tableData: [],
		tableSpanData: computed(() => {
			if (state.spanColumns?.length > 0 && state.tableData?.length > 0) {
				const result = [];
				state.spanColumns.forEach((item) => {
					result[item.prop] = new Array(state.tableData.length).fill(1, 0, 1).fill(0, 1);
					result[`${item.prop}-index`] = 0;
				});
				for (let i = 1; i < state.tableData.length; i++) {
					state.spanColumns.forEach((item) => {
						if (state.tableData[i][item.spanProp] === state.tableData[i - 1][item.spanProp]) {
							result[item.prop][result[`${item.prop}-index`]]++;
						} else {
							result[`${item.prop}-index`] = i;
							result[item.prop][i] = 1;
						}
					});
				}
				return result;
			}
			return [];
		}),
		tablePagination: {
			pageIndex: 1,
			pageSize: 20,
			totalRows: 0,
		},
		searchParam: {},
		showSearch: GejiaApp.table.showSearch,
		selected: false,
		selectedList: [],
		selectedListIds: computed(() => state.selectedList.map((item) => (isFunction(props.rowKey) ? props.rowKey(item) : item[props.rowKey]))),
		indeterminateSelectedListIds: [],
		responseConfig: undefined,
		operationColumnWidth: computed(() => {
			const findAutoCol = state.autoColumnWidth.find((f) => f.prop === "__table-operation");
			if (findAutoCol) {
				return `${findAutoCol.width}px`;
			}
			switch (_globalSize.value) {
				case "large":
				case "default":
					return "54px";
				case "small":
					return "42px";
				default:
					return "auto";
			}
		}),
		imagePreview: false,
		previewList: [],
		tableWidth: undefined,
		tableHeight: undefined,
		autoColumnWidth: [],
		columnBtn: computed(() => {
			if (
				props.columnSettingBtn &&
				!props.columns &&
				GejiaApp.table.columnsSettings.get &&
				GejiaApp.table.columnsSettings.save &&
				GejiaApp.table.columnsSettings.sync &&
				GejiaApp.table.columnsSettings.clear
			) {
				return true;
			} else {
				return false;
			}
		}),
		exportBtn: computed(() => {
			if (props.pagination && props.requestApi && props.exportBtn && state.responseConfig && GejiaApp.table.exportApi) {
				return true;
			} else {
				return false;
			}
		}),
		existCacheColumns: false,
	});

	const elementRef = ref<HTMLElement>();
	const tableRef = ref<TableInstance>();

	/**
	 * 定义 enumMap 存储 enum 值（避免异步请求无法格式化单元格内容 || 无法填充搜索下拉选择）
	 */
	const enumMap = reactive(new Map<string, GTableEnumColumnCtx[]>());
	provide(enumMapKey, enumMap);

	provide(tableStateKey, state);

	const handleTableColumnAutoWidth = (): void => {
		state.loading = true;
		state.loadingText = "加载中...";
		state.autoColumnWidth = [];
		const autoWidthColumns = state.tableColumns.filter((f) => f.autoWidth);
		if (slots?.operation) {
			// 操作列自动宽度
			autoWidthColumns.push({
				prop: "__table-operation",
			});
		}
		if (autoWidthColumns?.length > 0) {
			// padding24/16 + border1
			const otherWidth = _globalSize.value === "default" ? 25 : 17;
			nextTick(() => {
				const tableDom = document.querySelector(`.g-table__${props.tableKey}`);
				if (tableDom) {
					autoWidthColumns.forEach((item) => {
						const headerColumnDom = tableDom.querySelector(`.__g-table__auto-width-column__cell-header__${item?.prop}`);
						const cellColumnDoms = tableDom.querySelectorAll(`.__g-table__auto-width-column__cell__${item?.prop}`);
						let maxWidth = 0;
						if (headerColumnDom) {
							maxWidth = Math.ceil(headerColumnDom.scrollWidth) + otherWidth;
							if (item?.sortable) {
								maxWidth += 24;
							}
						}
						cellColumnDoms.forEach((cellDom) => {
							const curWidth = Math.ceil(cellDom.scrollWidth) + otherWidth;
							if (curWidth > maxWidth) {
								maxWidth = curWidth;
							}
						});
						const findInfo = state.autoColumnWidth.find((f) => f.prop === item?.prop);
						if (findInfo) {
							findInfo.width = Math.max(findInfo.width, maxWidth);
						} else {
							state.autoColumnWidth.push({
								prop: item?.prop,
								width: maxWidth,
							});
						}
					});
				}
			});
		}
		state.loading = false;
	};

	const handleTableData = (data: any[]): any[] => {
		if (props.treeData) {
			const result: any[] = [];
			data.forEach((row) => {
				const rowList = row[props.props.children];
				if (isArray(rowList)) {
					// 如果 rowList 是数组，遍历并合并每个子项
					rowList.forEach((cRow) => result.push({ ...row, ...cRow }));
				} else {
					result.push({ ...row, ...(rowList || {}) });
				}
			});
			return result;
		} else {
			return data;
		}
	};

	const getRequestParam = (): PageInput => {
		const params = { ...(props.initParam ?? {}), ...state.searchParam, ...(props.pagination ? state.tablePagination : {}) };
		// 删除总条数
		delete params.totalRows;
		return params;
	};

	const loadData = async (searchParam?: PageInput): Promise<void> => {
		state.loading = true;
		state.loadingText = "加载中...";
		if (props.requestApi) {
			const params = { ...(searchParam ?? {}), ...getRequestParam() };
			emit("refresh", params);
			let pageData = [];
			try {
				state.responseConfig = {
					url: "",
					method: "POST",
				};
				// 获取函数的源码字符串
				const funcStr = props.requestApi.toString();
				// 使用正则表达式提取 url 和 method 的值
				const urlMatch = funcStr.match(/url:\s*"([^"]+)"/);
				if (urlMatch) {
					state.responseConfig.url = urlMatch[1];
				}
				const methodMatch = funcStr.match(/method:\s*"([^"]+)"/);
				if (methodMatch) {
					state.responseConfig.method = methodMatch[1];
				}
				const resData = await props.requestApi(params);
				// 数据回调
				props.dataCallback && props.dataCallback(resData);
				// 解析 API 接口返回的分页数据（如果有分页更新分页信息）
				if (props.pagination) {
					const pageRes = resData as PageResult;
					pageData = pageRes.rows;
					// 更新分页信息
					Object.assign(state.tablePagination, {
						pageIndex: pageRes.pageIndex,
						pageSize: pageRes.pageSize,
						totalRows: pageRes.totalRows,
					});
				} else {
					pageData = resData as any[];
					// 更新分页信息
					Object.assign(state.tablePagination, {
						pageIndex: 1,
						pageSize: 0,
						totalRows: pageData.length,
					});
				}
				state.tableData = handleTableData(pageData);
			} catch (error) {
				consoleError("GTable", error);
				state.tableData = [];
			} finally {
				state.loading = false;
			}
		} else {
			emit("refresh", { searchValue: state.searchParam.searchValue });
			let _value = handleTableData(props.data);
			_value = _value.filter((f) => {
				if (!state.searchParam.searchValue) return true;
				return state.tableColumns.some((col) => {
					return f[col.prop]?.toString()?.toLowerCase().includes(state.searchParam.searchValue?.toLowerCase());
				});
			});
			if (state.searchParam.sortList?.length > 0) {
				_value = _value.sort(tableUtil.arrayDynamicSort(state.searchParam.sortList));
			}
			if (props.pagination) {
				state.tablePagination.totalRows = _value.length;
				const pageStart = (state.tablePagination.pageIndex - 1) * state.tablePagination.pageSize;
				const pageEnd = pageStart + state.tablePagination.pageSize;
				state.tableData = _value.slice(pageStart, pageEnd);
			} else {
				state.tableData = _value;
			}
			state.loading = false;
		}
		handleTableColumnAutoWidth();
	};

	/** type: 1 字符串，2 数字，3 Boolean，4 方法 */
	type GTableColumnLocalCtx = {
		otherAdvancedConfig?: { prop: string; type: number }[];
		searchAdvancedConfig?: { prop: string; type: number }[];
	};

	const saveColumnsCache = async (change = true): Promise<void> => {
		if (props.columns) return;
		if (!GejiaApp.table.columnsSettings.save) {
			ElNotification({
				message: "未实现表格列保存方法",
				type: "error",
			});
			return;
		}
		if (change) {
			state.loading = true;
			state.loadingText = "保存列配置中...";
			try {
				await GejiaApp.table.columnsSettings.save({
					tableKey: props.tableKey,
					columns: state.orgColumns.map((m) => ({
						columnID: m.columnID,
						label: m.label,
						fixed: m.fixed,
						width: Number(m.width),
						smallWidth: Number(m.smallWidth),
						order: m.order,
						sortable: m.sortable,
						copy: m.copy,
						autoWidth: m.autoWidth,
						show: m.show,
						search: {
							label: m.search?.label,
							order: m.search?.order,
						},
					})),
				});
				// 这里已经存在缓存列了
				state.existCacheColumns = true;
				GejiaApp.setTableColumns(props.tableKey, state.orgColumns);
				ElMessage.success("保存列配置成功");
			} catch (error) {
				consoleError("GTable", error);
				ElMessage.error("保存列配置失败");
			} finally {
				state.loading = false;
			}
		} else {
			ElMessage.info("列配置未发生变化");
		}
	};

	const syncColumnsCache = async (showConfirm = true): Promise<void> => {
		if (props.columns) return;
		if (!GejiaApp.table.columnsSettings) {
			ElNotification({
				message: "未实现表格列同步方法",
				type: "error",
			});
			return;
		}
		async function localRequest(): Promise<void> {
			state.loading = true;
			state.loadingText = "同步列配置中...";
			try {
				await GejiaApp.table.columnsSettings.sync({ tableKey: props.tableKey });
				GejiaApp.deleteTableColumns(props.tableKey);
				ElMessage.success("同步成功");
				state.loading = false;
				// eslint-disable-next-line no-use-before-define
				await loadTableColumns();
			} catch (error) {
				consoleError("GTable", error);
				ElMessage.error("同步失败");
			} finally {
				state.loading = false;
			}
		}
		if (showConfirm) {
			ElMessageBox.confirm("确认同步列缓存配置？此操作无法撤销。", {
				type: "warning",
				async beforeClose(action, instance, done) {
					await localRequest();
				},
			}).then();
		} else {
			await localRequest();
		}
	};

	const clearColumnsCache = async (): Promise<void> => {
		if (props.columns) return;
		if (!GejiaApp.table.columnsSettings.clear) {
			ElNotification({
				message: "未实现表格列清除方法",
				type: "error",
			});
			return;
		}
		ElMessageBox.confirm("确认重置列缓存配置？此操作无法撤销。", {
			type: "warning",
			async beforeClose(action, instance, done) {
				state.loading = true;
				state.loadingText = "重置列配置中...";
				try {
					await GejiaApp.table.columnsSettings.clear({ tableKey: props.tableKey });
					GejiaApp.deleteTableColumns(props.tableKey);
					ElMessage.success("重置成功");
					state.loading = false;
					// eslint-disable-next-line no-use-before-define
					await loadTableColumns();
				} catch (error) {
					consoleError("GTable", error);
					ElMessage.error("保存列配置失败");
				} finally {
					state.loading = false;
				}
			},
		});
	};

	const handleColumnType = (localColumns: (GTableColumnCtx & GTableColumnLocalCtx)[]): (GTableColumnCtx & GTableColumnLocalCtx)[] => {
		const handleFunctionArgs = (
			functionStr: string
		): {
			args: string[];
			body: string;
		} => {
			// 去掉字符串开头和结尾的多余空白字符，包括换行符
			const trimmedStr = functionStr.trim();

			// 正则表达式用于匹配箭头函数的参数和函数体，包括支持解构参数
			const arrowFunctionMatch = trimmedStr.match(/^\s*\(?([^)]*?)\)?\s*=>\s*{([\s\S]*?)}\s*;?$/);

			if (arrowFunctionMatch) {
				const args = arrowFunctionMatch[1]
					.split(",")
					.map((arg) => arg.trim())
					.filter((arg) => arg);
				const body = arrowFunctionMatch[2].trim();
				return { args, body };
			}

			return { args: [], body: "" };
		};

		for (let i = 0; i < localColumns.length; i++) {
			if (localColumns[i]?.otherAdvancedConfig?.length > 0) {
				localColumns[i]?.otherAdvancedConfig
					.filter((f) => f.type === 4)
					.forEach((advKey: { prop: string; type: number }) => {
						const { args, body } = handleFunctionArgs(localColumns[i][advKey.prop]);
						localColumns[i][advKey.prop] = new Function(...args, body);
					});
				delete localColumns[i].otherAdvancedConfig;
			}
			if (localColumns[i]?.searchAdvancedConfig?.length > 0) {
				localColumns[i]?.searchAdvancedConfig
					.filter((f) => f.type === 4)
					.forEach((advKey: { prop: string; type: number }) => {
						const { args, body } = handleFunctionArgs(localColumns[i].search[advKey.prop]);
						localColumns[i].search[advKey.prop] = new Function(...args, body);
					});
				delete localColumns[i].searchAdvancedConfig;
			}
		}

		return localColumns;
	};

	const loadTableColumns = async (): Promise<void> => {
		let columns: GTableColumnCtx[] = [];
		if (props.columns) {
			columns = props.columns;
		} else {
			const cacheColumns = GejiaApp.getTableColumns(props.tableKey, false);
			if (cacheColumns) {
				columns = cacheColumns;
			} else {
				if (!GejiaApp.table.columnsSettings) {
					ElNotification({
						message: "未实现表格列获取方法",
						type: "error",
					});
					return;
				}
				state.loading = true;
				state.loadingText = "加载列配置中...";
				try {
					const dataRes = await GejiaApp.table.columnsSettings.get(props.tableKey);
					// 判断是否存在改变
					if (dataRes.change) {
						const lastUpdateTime = dataRes.lastUpdateTime ?? new Date();
						ElMessage.info(
							`当前列配置于 '${dayjs(lastUpdateTime).format("YYYY-MM-DD")}' 已发生改变，为确保数据准确性，正在同步缓存配置，请稍后...`
						);
						state.loading = false;
						// 同步
						await syncColumnsCache(false);
						return;
					} else {
						state.existCacheColumns = dataRes.cache;
						columns = handleColumnType(dataRes.columns);
						GejiaApp.setTableColumns(props.tableKey, columns);
					}
				} catch (error) {
					consoleError("GTable", error);
					state.orgColumns = [];
				} finally {
					state.loading = false;
				}
			}
		}

		// 默认值处理
		columns.forEach((col) => {
			// 处理搜索项的 key 和 label
			if (col.pureSearch || col.search) {
				col.search.key ??= col.prop;
				col.search.label ??= col.label;
				// 处理默认值
				if (col.search.defaultValue) {
					state.searchParam[col.search.key] = col.search.defaultValue;
				}
			}
		});

		// 排序
		columns = columns.sort((a, b) => {
			return a?.order - b?.order;
		});

		// TODO:这里的扁平化暂时没用到
		state.orgColumns = tableUtil.flatColumns(columns, enumMap);
	};

	const handleSizeChange = (pageSize: number): void => {
		state.tablePagination.pageIndex = 1;
		state.tablePagination.pageSize = pageSize;
		emit("sizeChange", pageSize);
		emit("paginationChange", 1, pageSize);
		loadData();
	};
	const handlePaginationChange = (val: number): void => {
		state.tablePagination.pageIndex = val;
		emit("sizeChange", state.tablePagination.pageSize);
		emit("paginationChange", val, state.tablePagination.pageSize);
		loadData();
	};

	const updatedTotalParam = (): void => {
		// 处理查询参数，可以给查询参数加自定义前缀操作
		const newSearchParam = {};
		// 防止手动清空输入框携带参数（这里可以自定义查询参数前缀）
		for (const key in state.searchParam) {
			// * 某些情况下参数为 false/0 也应该携带参数
			if (state.searchParam[key] || state.searchParam[key] === false || state.searchParam[key] === 0) {
				newSearchParam[key] = state.searchParam[key];
			}
			// 处理某些情况下如果为空字符串，其实是不需要传到后端的
			else if (!state.searchParam[key]) {
				delete state.searchParam[key];
			}
		}
		Object.assign(state.searchParam, newSearchParam);
	};

	const defaultSearchTime = (): void => {
		if (props.hideSearchTime) {
			state.searchParam.searchTimeList = undefined;
		} else {
			const end = new Date();
			const start = new Date();
			switch (GejiaApp.table.dataSearchRange) {
				case "Past1D":
					start.setDate(start.getDate() - 1);
					break;
				case "Past3D":
					start.setDate(start.getDate() - 3);
					break;
				case "Past1W":
					start.setDate(start.getDate() - 7);
					break;
				case "Past1M":
					start.setMonth(start.getMonth() - 1);
					break;
				case "Past3M":
					start.setMonth(start.getMonth() - 3);
					break;
				case "Past6M":
					start.setMonth(start.getMonth() - 6);
					break;
				case "Past1Y":
					start.setFullYear(start.getFullYear() - 1);
					break;
				case "Past3Y":
					start.setFullYear(start.getFullYear() - 3);
					break;
			}
			state.searchParam.searchTimeList = [dayjs(start).format("YYYY-MM-DD 00:00:00"), dayjs(end).format("YYYY-MM-DD 23:59:59")];
		}
	};

	const tableSearch = async (searchParam?: PageInput): Promise<void> => {
		// 重置到第一页
		state.tablePagination.pageIndex = 1;
		updatedTotalParam();
		await loadData(searchParam);
	};

	const tableReset = async (): Promise<void> => {
		// 重置到第一页
		state.tablePagination.pageIndex = 1;
		// 清除搜索条件
		state.searchParam = {};
		// 重置搜索表单的时候，如果有默认搜索参数，则重置默认的搜索参数
		Object.keys(props.initParam ?? {}).forEach((key) => {
			state.searchParam[key] = props.initParam[key];
		});
		defaultSearchTime();
		await loadData();
	};

	const doRender = async (): Promise<void> => {
		state.orgColumns = [];
		state.autoColumnWidth = [];
		state.tableData = [];
		await clickUtil.debounceAsync(async () => {
			await loadTableColumns();
			await tableSearch();
		}, 300);
	};

	const handleCustomCellClick = (emitName: string, { row, column, $index }: { row: any; column: GTableColumnCtx; $index: number }): void => {
		emit("customCellClick", emitName, { row, column, $index, ...getTableDefaultSlots(state) });
	};

	watch(
		() => props.tableKey,
		async () => {
			await doRender();
		}
	);

	watch(
		() => props.data,
		async () => {
			if (!props.requestApi) {
				await tableSearch();
			}
		},
		{ deep: true, immediate: true }
	);

	watchEffect(() => {
		const element = elementRef.value;
		if (element) {
			const observer = new ResizeObserver((entries) => {
				for (const entry of entries) {
					const { width, height } = entry.contentRect;
					state.tableWidth = width;
					state.tableHeight = height;
				}
				clickUtil.debounce(handleTableColumnAutoWidth, 100);
			});
			observer.observe(element);

			return (): void => {
				observer.disconnect();
			};
		}
	});

	return {
		_globalSize,
		state,
		elementRef,
		tableRef,
		getRequestParam,
		saveColumnsCache,
		syncColumnsCache,
		clearColumnsCache,
		loadTableColumns,
		handleSizeChange,
		handlePaginationChange,
		defaultSearchTime,
		tableSearch,
		tableReset,
		doRender,
		handleCustomCellClick,
	};
};
