import { computed, defineComponent, reactive } from "vue";
import { ElText, textProps } from "element-plus";
import { isNil } from "lodash-unified";
import { GejiaApp } from "@gejia-element-plus/settings";
import { makeSlots, useExpose, useProps, useRender } from "@gejia-element-plus/utils";

export const gTextProps = {
	...textProps,
	/** @description 字典名称/Key */
	name: String,
	/** @description 值 */
	value: {
		type: [Number, String, Boolean],
		default: undefined,
	},
};

type GTextSlots = {
	/** @description 默认内容插槽，如果使用原生的功能，则不会返回任何值 */
	default: { label?: string; tips?: string };
};

export default defineComponent({
	name: "GText",
	props: gTextProps,
	slots: makeSlots<GTextSlots>(),
	setup(props, { attrs, slots, emit, expose }) {
		const dictionaries = computed(() => (props.name ? GejiaApp.getDictionary(props.name) : []));

		const state = reactive({
			dictionary: computed(() => (isNil(props.value) ? null : dictionaries.value.find((f) => f.value === props.value))),
		});

		const elTextProps = useProps(props, textProps, ["type"]);

		useRender(() =>
			props.name ? (
				state.dictionary?.show && (
					<ElText {...elTextProps.value} class="g-text" type={state.dictionary?.type ?? props.type} title={state.dictionary?.tips}>
						{{
							default: () =>
								slots.default
									? slots.default({ label: state.dictionary.label, tips: state.dictionary.tips })
									: state.dictionary.label,
						}}
					</ElText>
				)
			) : (
				<ElText {...elTextProps.value} class="g-text" type={props.type}>
					{{
						default: () => slots.default && slots.default({}),
					}}
				</ElText>
			)
		);

		return useExpose(expose, {
			/** @description 字典 */
			dictionary: computed(() => state.dictionary),
		});
	},
});
