import { computed, defineComponent, inject } from "vue";
import { ElMessageBox, ElPagination } from "element-plus";
import { tableStateKey } from "./useTable";
import { GejiaApp } from "@gejia-element-plus/settings";
import { definePropType, useRender } from "@gejia-element-plus/utils";

export default defineComponent({
	name: "GTablePagination",
	props: {
		/** @description 页码改变 */
		sizeChange: {
			type: definePropType<(pageSize: number) => void>(Function),
			required: true,
		},
		/** @description 当前页数改变 */
		currentChange: {
			type: definePropType<(currentPage: number) => void>(Function),
			required: true,
		},
	},
	setup(props) {
		const tableState = inject(tableStateKey);

		const pageSizes = computed(() =>
			GejiaApp.env === "development" ? [20, 30, 50, 100, 200, 300, 500, 1000] : [20, 30, 50, 100, 200, 300, 500]
		);

		const handleSizeChange = (pageSize: number): void => {
			if (pageSize > 100) {
				ElMessageBox.confirm("当前页码已经超过100条/页，可能会造成页面卡顿，是否继续？", {
					confirmButtonText: "继续",
					type: "warning",
				}).then(() => {
					props.sizeChange(pageSize);
				});
			} else {
				props.sizeChange(pageSize);
			}
		};

		useRender(() => (
			<ElPagination
				class="g-table-pagination"
				size="small"
				vModel:currentPage={tableState.tablePagination.pageIndex}
				vModel:pageSize={tableState.tablePagination.pageSize}
				pageSizes={pageSizes.value}
				background
				layout="jumper, prev, pager, next, sizes, total"
				total={tableState.tablePagination.totalRows}
				onSizeChange={handleSizeChange}
				onCurrentChange={props.currentChange}
			/>
		));
	},
});
