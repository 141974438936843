import { defineComponent, inject, reactive, ref, watch } from "vue";
import { Eleme, Refresh, Search } from "@element-plus/icons-vue";
import { ElButton } from "element-plus";
import type { GTableColumnCtx, GTableDefaultSlotsResult, GTableSearchColumnCtx } from "./table.type";
import { getTableDefaultSlots } from "./table.type";
import GTableSearchFormItem from "./tableSearchFormItem";
import { tableStateKey } from "./useTable";
import type { GDrawerInstance } from "@gejia-element-plus/components/drawer";
import { GDrawer } from "@gejia-element-plus/components/drawer";
import { GLayoutGrid, GLayoutGridItem } from "@gejia-element-plus/components/layoutGrid";
import type { GLayoutGridBreakPoint, GLayoutGridItemResponsive } from "@gejia-element-plus/components/layoutGrid";
import { Clear } from "@gejia-element-plus/icons-vue";
import { definePropType, makeSlots, useRender, withDefineType } from "@gejia-element-plus/utils";

type GTableSearchFormSlots = {
	[key: string]: GTableDefaultSlotsResult & {
		column?: GTableColumnCtx;
		search?: () => void;
	};
};

export default defineComponent({
	name: "GTableSearchForm",
	props: {
		/** @description 显示 */
		show: {
			type: Boolean,
			required: true,
		},
		/** @description Grid布局列配置 */
		cols: {
			type: definePropType<string | number | Record<GLayoutGridBreakPoint, number>>([String, Number, Object]),
			default: () => ({ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }),
		},
		/** @description 搜索 */
		search: {
			type: definePropType<() => void>(Function),
			required: true,
		},
		/** @description 重置 */
		reset: {
			type: definePropType<() => void>(Function),
			required: true,
		},
	},
	slots: makeSlots<GTableSearchFormSlots>(),
	setup(props, { slots, expose }) {
		const state = reactive({
			height: "auto",
			/** 折叠 */
			collapsed: true,
			searchColumns: withDefineType<GTableColumnCtx[]>([]),
			advancedSearchColumns: withDefineType<GTableColumnCtx[]>([]),
			breakPoint: undefined,
		});

		const tableState = inject(tableStateKey);

		const gTableSearchFormRef = ref<HTMLElement>();
		const advancedSearchRef = ref<GDrawerInstance>();

		// 获取响应式设置
		const getResponsive = (
			item: GTableSearchColumnCtx
		): {
			span: number;
			offset: number;
			xs: GLayoutGridItemResponsive;
			sm: GLayoutGridItemResponsive;
			md: GLayoutGridItemResponsive;
			lg: GLayoutGridItemResponsive;
			xl: GLayoutGridItemResponsive;
		} => {
			return {
				span: item?.span,
				offset: item?.offset ?? 0,
				xs: item?.xs,
				sm: item?.sm,
				md: item?.md,
				lg: item?.lg,
				xl: item?.xl,
			};
		};

		// 获取响应式断点
		const gridRef = ref();

		const handleBreakPointChange = ({ breakPoint }: { breakPoint: GLayoutGridBreakPoint }): void => {
			// 这里 -1 是排除固定的
			state.breakPoint = props.cols[breakPoint] - 1;
			state.searchColumns = tableState.searchColumns.filter((f) => f?.show).slice(0, state.breakPoint);
			state.advancedSearchColumns = tableState.searchColumns.filter((f) => f?.show).slice(state.breakPoint);
		};

		watch(
			() => tableState.searchColumns,
			() => {
				if (state.breakPoint) {
					state.searchColumns = tableState.searchColumns.filter((f) => f?.show).slice(0, state.breakPoint);
					state.advancedSearchColumns = tableState.searchColumns.filter((f) => f?.show).slice(state.breakPoint);
				}
			}
		);

		useRender(
			() =>
				tableState.searchColumns.length > 0 && (
					<div
						ref={gTableSearchFormRef}
						class={["el-card g-table__search", { "g-table__search-hidden": !props.show, "g-table__search__disable": tableState.loading }]}
					>
						<form class="el-form el-form--default el-form--label-right">
							<GLayoutGrid ref={gridRef} collapsed gap={[20, 0]} cols={props.cols} onBreakPointChange={handleBreakPointChange}>
								{state.searchColumns.map((item, index) => (
									<GLayoutGridItem key={item?.search?.key ?? item.prop} {...getResponsive(item.search)} index={index}>
										<div class="el-form-item el-form-item--default el-form-item--label-right">
											<label class="el-form-item__label">{item.search.label}</label>
											<div class="el-form-item__content">
												{item.search?.slot ? (
													slots[item.search.slot] &&
													slots[item.search.slot]({
														column: item,
														search: props.search,
														...getTableDefaultSlots(tableState),
													})
												) : (
													<GTableSearchFormItem column={item} search={props.search} />
												)}
											</div>
										</div>
									</GLayoutGridItem>
								))}
								<GLayoutGridItem suffix>
									<div class="g-table__search-operation">
										<ElButton
											loading={tableState.loading}
											loadingIcon={Eleme}
											title="刷新"
											type="primary"
											icon={Refresh}
											onClick={props.search}
										/>
										<ElButton loading={tableState.loading} loadingIcon={Eleme} title="重置" icon={Clear} onClick={props.reset} />
										{state.advancedSearchColumns.length > 0 && (
											<ElButton
												loading={tableState.loading}
												loadingIcon={Eleme}
												title="高级搜索"
												icon={Search}
												onClick={() => advancedSearchRef.value.open()}
											/>
										)}
									</div>
								</GLayoutGridItem>
							</GLayoutGrid>
						</form>
						{state.advancedSearchColumns.length > 0 && (
							<GDrawer
								ref={advancedSearchRef}
								class="g-table__search-advanced"
								size="45%"
								title="高级搜索"
								appendToBody={false}
								showCloseButton={false}
								showConfirmButton={false}
								showFullscreen={false}
								showRefresh={false}
								onConfirmClick={() => props.search()}
							>
								<form class="el-form el-form--default el-form--label-top">
									<GLayoutGrid gap={[20, 0]} cols={{ xs: 2, sm: 3, md: 4, lg: 5, xl: 6 }}>
										{state.advancedSearchColumns.map((item, index) => (
											<GLayoutGridItem key={item.prop ?? item?.search?.key} {...getResponsive(item.search)} index={index}>
												<div class="el-form-item el-form-item--default el-form-item--label-top">
													<label class="el-form-item__label">{item.search.label}</label>
													<div class="el-form-item__content">
														{item.search.slot ? (
															slots[item.search.slot] &&
															slots[item.search.slot]({
																column: item,
																search: props.search,
																...getTableDefaultSlots(tableState),
															})
														) : (
															<GTableSearchFormItem column={item} search={props.search} />
														)}
													</div>
												</div>
											</GLayoutGridItem>
										))}
									</GLayoutGrid>
								</form>
							</GDrawer>
						)}
					</div>
				)
		);
	},
});
