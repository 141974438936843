import type { ExtractPropTypes } from "vue";
import Image, { gImageProps } from "./src/image";
import { withInstall } from "@gejia-element-plus/utils";

export const GImage = withInstall(Image);
export default GImage;

export { gImageProps };

export type GImageInstance = InstanceType<typeof Image>;

export type GImageProps = ExtractPropTypes<typeof gImageProps>;
