import type { ExtractPropTypes } from "vue";
import Tree, { gTreeEmits, gTreeProps } from "./src/tree";
import { withInstall } from "@gejia-element-plus/utils";

export const GTree = withInstall(Tree);
export default GTree;

export { gTreeProps, gTreeEmits };
export * from "./src/tree.type";

export declare type GTreeInstance = InstanceType<typeof Tree>;

export declare type GTreeProps = ExtractPropTypes<typeof gTreeProps>;

export declare type GTreeEmits = typeof gTreeEmits;
