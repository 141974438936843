/**
 * 对象工具类
 */
export const objectUtl = {
	/**
	 * 对象URL参数化
	 */
	objectToQueryString(obj: any): string {
		let params = "";
		for (const key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				if (params !== "") {
					params += "&";
				}
				params += `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`;
			}
		}
		return params;
	},
	/**
	 * 是否存在重复值
	 */
	hasDuplicateProperty<T>(arr: T[], prop: keyof T): boolean {
		const values = arr.map((obj) => obj[prop]);
		const uniqueValues = new Set(values);
		return values.length !== uniqueValues.size;
	},
	/**
	 * 是否存在非重复值
	 */
	hasDifferentProperty<T>(arr: T[], prop: keyof T): boolean {
		const valueSet = new Set<any>();
		for (const obj of arr) {
			valueSet.add(obj[prop]);
			if (valueSet.size > 1) {
				return true;
			}
		}
		return false;
	},
};
