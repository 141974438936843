import { isNil, isString } from "lodash-unified";

class GejiaElementPlusError extends Error {
	constructor(m: string) {
		super(m);
		this.name = "GejiaElementPlusError";
	}
}

/**
 * 打印 Log 日志
 * @param name 来源名称
 */
export const consoleLog = (name: string, message?: string | false, error?: any): void => {
	if (error) {
		// eslint-disable-next-line no-console
		console.log(`[Gejia-Log-${name}]${message ? ` ${message}` : ""}`, error);
	} else {
		// eslint-disable-next-line no-console
		console.log(`[Gejia-Log-${name}]${message ? ` ${message}` : ""}`);
	}
};

/**
 * 打印 Warn 日志
 * @param name 来源名称
 */
export const consoleWarn = (name: string, message?: string | false, error?: any): void => {
	if (error) {
		// eslint-disable-next-line no-console
		console.warn(`[Gejia-Log-${name}]${message ? ` ${message}` : ""}`, error);
	} else {
		// eslint-disable-next-line no-console
		console.warn(`[Gejia-Log-${name}]${message ? ` ${message}` : ""}`);
	}
};

/**
 * 打印 Debug 日志
 * @param name 来源名称
 */
export const consoleDebug = (name: string, message?: string | false, error?: any): void => {
	if (error) {
		// eslint-disable-next-line no-console
		console.debug(`[Gejia-Log-${name}]${message ? ` ${message}` : ""}`, error);
	} else {
		// eslint-disable-next-line no-console
		console.debug(`[Gejia-Log-${name}]${message ? ` ${message}` : ""}`);
	}
};

/**
 * 打印 Error 日志
 * @param name 来源名称
 */
export const consoleError = (name: string, message?: any): void => {
	if (isNil(message)) {
		return;
	}
	if (isString(message)) {
		console.error(new GejiaElementPlusError(`[Gejia-${name}] ${message}`));
	} else {
		// eslint-disable-next-line no-console
		console.error(`[Gejia-Error-${name}]`, message);
	}
};

/**
 * 抛出错误
 * @param name 来源名称
 */
export const throwError = (name: string, message?: any): never => {
	throw new GejiaElementPlusError(`[Gejia-${name}] ${message}`);
};
