import { reactive } from "vue";
import type { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { merge } from "lodash-unified";
import type { GTableColumnCtx, GTableColumnDateFormat, GTableEnumColumnCtx } from "@gejia-element-plus/components";
import { Local, Session, consoleError, makeIdentity } from "@gejia-element-plus/utils";

/**
 * Vite 环境
 */
export type ViteEnv = "production" | "development" | "test" | "staging";

/**
 * 日期范围
 */
export type DataRange = "Past1D" | "Past3D" | "Past1W" | "Past1M" | "Past3M" | "Past6M" | "Past1Y" | "Past3Y";

type TableSaveColumns = {
	/**
	 * 列ID
	 */
	columnID?: number;
	/**
	 * 名称
	 */
	label?: string;
	/**
	 * 固定(可空)
	 * left：左侧
	 * right：右侧
	 */
	fixed?: any;
	/**
	 * 宽度
	 */
	width?: number;
	/**
	 * 小的宽度
	 */
	smallWidth?: number;
	/**
	 * 顺序
	 */
	order?: number;
	/**
	 * 是否排序
	 */
	sortable?: boolean;
	/**
	 * 复制
	 */
	copy?: boolean;
	/**
	 * 自动宽度
	 */
	autoWidth?: boolean;
	/**
	 * 是否显示
	 */
	show?: boolean;
	/**
	 *
	 */
	search?: {
		/**
		 * 名称
		 */
		label?: string;
		/**
		 * 顺序
		 */
		order?: number;
	};
};

/**
 * Gejia App 配置选项
 */
export type GejiaAppOptions = {
	/**
	 * 环境
	 * @default "production"
	 */
	env?: ViteEnv;
	/**
	 * 存储加密，请在初始化的时候确认，后续不可再修改，否则所有数据都将失效
	 * @default true
	 */
	storageCrypto?: boolean;
	/**
	 * Axios 配置
	 */
	axios?: {
		/**
		 * 请求域名或者Base路径
		 */
		baseUrl?: string;
		/**
		 * 超时时间，单位毫秒
		 * @default 60000
		 */
		timeout?: number;
		/**
		 * 请求加密解密
		 * @default true
		 */
		requestCipher?: boolean;
		/**
		 * 拦截器
		 */
		interceptors?: {
			/**
			 * 请求拦截器
			 */
			request?: <Input = any>(config: InternalAxiosRequestConfig<Input>) => void;
			/**
			 * 响应拦截器
			 */
			response?: <Output = any, Input = any>(response: AxiosResponse<Output, Input>) => any | void;
			/**
			 * 响应错误处理
			 */
			responseError?: (error: AxiosError | any) => any | void;
		};
	};
	/**
	 * 表格配置
	 */
	table?: {
		/**
		 * 显示搜索Form
		 * @default true
		 */
		showSearch?: boolean;
		/**
		 * 隐藏图片
		 * @default true
		 */
		hideImage?: boolean;
		/**
		 * 时间搜索范围
		 */
		dataSearchRange?: DataRange;
		/**
		 * 列配置
		 */
		columnsSettings?: {
			/**
			 * 获取
			 */
			get: (tableKey: string) => Promise<{
				/** 是否存在缓存列 */
				cache: boolean;
				/** 是否存在变更 */
				change: boolean;
				/** 最后更新时间 */
				lastUpdateTime?: Date;
				columns: GTableColumnCtx[];
			}>;
			/**
			 * 保存
			 */
			save: ({ tableKey, columns }: { tableKey: string; columns: TableSaveColumns[] }) => Promise<void>;
			/**
			 * 同步
			 */
			sync: ({ tableKey }: { tableKey: string }) => Promise<void>;
			/**
			 * 清除
			 */
			clear: ({ tableKey }: { tableKey: string }) => Promise<void>;
		};
		/**
		 * 导出请求
		 */
		exportApi?: (data: {
			pageTitle: string;
			searchParam: any;
			apiUrl: string;
			method: string;
			columns: {
				prop: string;
				label: string;
				dateFormat: GTableColumnDateFormat;
				enum: GTableEnumColumnCtx[];
			}[];
		}) => Promise<void>;
	};
	/**
	 * 上传配置
	 */
	upload?: {
		/**
		 * 上传Api
		 */
		api?: (formData: FormData) => Promise<string>;
		/**
		 * 上传地址
		 */
		url?: string;
	};
	/**
	 * 打印配置
	 */
	print?: {
		/**
		 * 打印环境
		 */
		environment?: boolean;
		/**
		 * 查询模板Api
		 */
		templateApi?: (printType: number) => Promise<gePrintTemplate[]>;
	};
};

const state = reactive<GejiaAppOptions>({
	env: "production",
	storageCrypto: true,
	axios: {
		baseUrl: undefined,
		timeout: 60000,
		requestCipher: true,
	},
	table: {
		showSearch: true,
		hideImage: true,
		dataSearchRange: "Past3D",
		columnsSettings: {
			get: undefined,
			save: undefined,
			sync: undefined,
			clear: undefined,
		},
		exportApi: undefined,
	},
	upload: {
		api: undefined,
		url: undefined,
	},
	print: {
		environment: false,
		templateApi: undefined,
	},
});

const stateMap = reactive({
	dictionary: new Map<string, GTableEnumColumnCtx[]>(),
	columns: new Map<string, GTableColumnCtx[]>(),
});

export class GejiaApp {
	/**
	 * 环境
	 */
	public static get env(): GejiaAppOptions["env"] {
		return state.env;
	}

	/**
	 * 存储加密，请在初始化的时候确认，后续不可再修改，否则所有数据都将失效
	 */
	public static get storageCrypto(): GejiaAppOptions["storageCrypto"] {
		return state.storageCrypto;
	}

	/**
	 * Axios 配置
	 */
	public static get axios(): GejiaAppOptions["axios"] {
		return state.axios;
	}

	/**
	 * 表格配置
	 */
	public static get table(): GejiaAppOptions["table"] {
		return state.table;
	}

	/**
	 * 上传配置
	 */
	public static get upload(): GejiaAppOptions["upload"] {
		return state.upload;
	}

	/**
	 * 打印配置
	 */
	public static get print(): GejiaAppOptions["print"] {
		return state.print;
	}

	/**
	 * 设置 App 配置
	 */
	public static setAppOptions = (appOptions: GejiaAppOptions): void => {
		merge(state, appOptions);
	};

	/**
	 * 设置打印环境
	 */
	public static setPrintEnvironment = (printEnvironment: boolean): void => {
		state.print.environment = printEnvironment;
	};

	/**
	 * 设置字典
	 */
	public static setDictionary = (dictionary: Map<string, GTableEnumColumnCtx[]>): void => {
		stateMap.dictionary = dictionary;
	};

	/**
	 * 获取字典
	 */
	public static getDictionary = (key: string, throwError = true): GTableEnumColumnCtx[] => {
		if (!stateMap.dictionary.has(key)) {
			if (throwError) {
				consoleError("app", `字典 [${key}] 不存在`);
			}
			return;
		}
		return stateMap.dictionary.get(key);
	};

	/**
	 * 获取表格列
	 */
	public static getTableColumns = (tableKey: string, throwError = true): GTableColumnCtx[] => {
		if (!stateMap.columns.has(tableKey)) {
			if (throwError) {
				consoleError("app", `表格列 [${tableKey}] 不存在`);
			}
			return;
		}
		return stateMap.columns.get(tableKey);
	};

	/**
	 * 设置或更新表格列
	 */
	public static setTableColumns = (tableKey: string, columns: GTableColumnCtx[]): void => {
		if (stateMap.columns.has(tableKey)) {
			stateMap.columns.delete(tableKey);
		}
		stateMap.columns.set(tableKey, columns);
	};

	/**
	 * 删除表格列
	 */
	public static deleteTableColumns = (tableKey: string): void => {
		if (stateMap.columns.has(tableKey)) {
			stateMap.columns.delete(tableKey);
		}
	};

	/**
	 * 清除 App 缓存
	 */
	public static clearAppCache = (): void => {
		// 获取设备Id，这里按理来说不应该不存在的
		const deviceId = makeIdentity();
		// 清空 Local 缓存
		Local.clear();
		// 清空 Session 缓存
		Session.clear();
		// 重新设置设备Id
		makeIdentity(deviceId);
	};
}
