import { ElNotification } from "element-plus";
import { pick } from "lodash-unified";
import type { NavigationFailure, RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw, Router } from "vue-router";
import { NavigationFailureType, isNavigationFailure } from "vue-router";
import { consoleError } from "@gejia-element-plus/utils";

export const routerUtil = {
	/**
	 * 路由跳转
	 * @param router 路由对象 useRouter()，因必须在 setup 中获取才存在值
	 * @param to 导航位置，同 router.push
	 */
	routePush(router: Router, to: RouteLocationRaw): Promise<NavigationFailure | void | undefined> {
		if (!router) {
			consoleError("routerUtil", new Error("useRouter undefined."));
			return Promise.resolve();
		}
		// let path: string = undefined;
		// if (isString(to)) {
		// 	path = to;
		// } else if ("path" in to) {
		// 	path = to.path;
		// } else if ("name" in to) {
		// 	const route = router.resolve(to);
		// 	path = route.path;
		// }
		return router.push(to);
	},
	/**
	 * 路由跳转，带错误检查
	 * @param router 路由对象 useRouter()，因必须在 setup 中获取才存在值
	 * @param to 导航位置，同 router.push
	 */
	routePushSafe(router: Router, to: RouteLocationRaw): Promise<NavigationFailure | void | undefined> {
		if (!router) {
			consoleError("routerUtil", new Error("useRouter undefined."));
			return Promise.resolve();
		}
		// let path: string = undefined;
		// if (isString(to)) {
		// 	path = to;
		// } else if ("path" in to) {
		// 	path = to.path;
		// } else if ("name" in to) {
		// 	const route = router.resolve(to);
		// 	path = route.path;
		// }
		router
			.push(to)
			.then((failure) => {
				if (failure) {
					if (isNavigationFailure(failure, NavigationFailureType.aborted)) {
						ElNotification({
							message: "导航失败，导航守卫拦截！",
							type: "error",
						});
					} else if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
						ElNotification({
							message: "导航失败，已在导航目标位置！",
							type: "warning",
						});
					}
				}
				return Promise.resolve(failure);
			})
			.catch((error) => {
				ElNotification({
					message: "导航失败，路由无效！",
					type: "error",
				});
				consoleError("routerUtil", error);
				return Promise.reject(error);
			});
	},
	/**
	 * route 部分属性，解决警告
	 */
	pickByRoute(route: Partial<RouteLocationNormalized>): Partial<RouteLocationNormalized> {
		return pick(route, ["name", "path", "query", "fullPath", "meta", "params"]);
	},
	/**
	 * 扁平化路由
	 */
	flattenRoutes(routes: RouteRecordRaw[]): RouteRecordRaw[] {
		const resRoutes: RouteRecordRaw[] = [];

		routes.forEach((item) => {
			if (item?.children?.length > 0) {
				const newItem = { ...item };
				delete newItem?.children;
				resRoutes.push(newItem);
				resRoutes.push(...this.flattenRoutes(item?.children));
			} else {
				resRoutes.push(item);
			}
		});

		return resRoutes;
	},
};
