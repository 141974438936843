import type { ExtractPropTypes } from "vue";
import type { gTextProps } from "./src/text";
import Text from "./src/text";
import { withInstall } from "@gejia-element-plus/utils";

export const GText = withInstall(Text);
export default GText;

export { gTextProps };

export type GTextInstance = InstanceType<typeof Text>;

export type GTextProps = ExtractPropTypes<typeof gTextProps>;
