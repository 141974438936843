import { computed, defineComponent, reactive } from "vue";
import { ElTag, tagEmits, tagProps } from "element-plus";
import { isNil } from "lodash-unified";
import { GejiaApp } from "@gejia-element-plus/settings";
import { makeSlots, useExpose, useProps, useRender } from "@gejia-element-plus/utils";

export const gTagProps = {
	...tagProps,
	/** @description 字典名称/Key */
	name: String,
	/** @description 值 */
	value: {
		type: [Number, String, Boolean],
		default: undefined,
	},
};

export const gTagEmits = {
	...tagEmits,
};

type GTagSlots = {
	/** @description 默认内容插槽，如果使用原生的功能，则不会返回任何值 */
	default: { label?: string; tips?: string };
};

export default defineComponent({
	name: "GTag",
	props: gTagProps,
	emits: gTagEmits,
	slots: makeSlots<GTagSlots>(),
	setup(props, { attrs, slots, emit, expose }) {
		const dictionaries = computed(() => (props.name ? GejiaApp.getDictionary(props.name) : []));

		const state = reactive({
			dictionary: computed(() => (isNil(props.value) ? null : dictionaries.value.find((f) => f.value === props.value))),
		});

		const elTagProps = useProps(props, tagProps, ["type"]);

		useRender(() =>
			props.name ? (
				state.dictionary?.show && (
					<ElTag
						{...elTagProps.value}
						class="g-tag"
						type={state.dictionary?.type ?? props.type}
						title={state.dictionary?.tips}
						onClick={(evt: MouseEvent) => emit("click", evt)}
						onClose={(evt: MouseEvent) => emit("close", evt)}
					>
						{{
							default: () =>
								slots.default
									? slots.default({ label: state.dictionary?.label, tips: state.dictionary?.tips })
									: state.dictionary?.label,
						}}
					</ElTag>
				)
			) : (
				<ElTag
					{...elTagProps.value}
					class="g-tag"
					type={props.type}
					onClick={(evt: MouseEvent) => emit("click", evt)}
					onClose={(evt: MouseEvent) => emit("close", evt)}
				>
					{{
						default: () => slots.default && slots.default({}),
					}}
				</ElTag>
			)
		);

		return useExpose(expose, {
			/** @description 字典 */
			dictionary: computed(() => state.dictionary),
		});
	},
});
